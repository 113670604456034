<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="道具名称" prop="name"  :required="true">
        <a-input v-model="form.name" placeholder="请输入道具名称"/>
      </a-form-model-item>
      <a-form-model-item label="价格" prop="price" :required="true">
        <a-input-number :min="0" v-model="form.price" placeholder="请输入价格" style="width: 200px;" />
      </a-form-model-item>
      <a-form-model-item label="道具类型" prop="type" :required="true">
        <a-select placeholder="请选择所属分类" optionFilterProp="children" show-search v-model="form.type">
          <a-select-option v-for="(item,key) in typeList" :key="item.name" :value="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="道具获取方式" prop="accessType" :required="true">
        <a-select placeholder="请选择道具获取方式" v-model="form.accessType">
          <a-select-option v-for="(d, index) in accessTypeOptions" :key="index" :value="d.value " >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="全屏显示" prop="isFullScreen" v-if="form.type==2" >
        <a-select v-model="form.isFullScreen" placeholder="请选择">
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="充值赠送累计到达金额" prop="rechargeMoney" :required="(form.type==2||form.type==5)&&form.accessType==3"  v-if="(form.type==2||form.type==5)&&form.accessType==3">
        <a-input-number :min="0" v-model="form.rechargeMoney" placeholder="请输入金额" style="width: 200px;" />
      </a-form-model-item>
      <a-form-model-item label="到达财富等级" prop="wealthLevelId" :required="(form.type==1||form.type==2||form.type==3)&&form.accessType==1"
                         v-if="(form.type==1||form.type==2||form.type==3)&&form.accessType==1">
        <a-select placeholder="请选择到达财富等级" v-model="form.wealthLevelId">
          <a-select-option v-for="(d, index) in wealthLevelList" :key="index" :value="d.id " >{{ d.name }}</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="道具logo" prop="logo" :required="true">
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.logo" :src="form.logo" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <svga-upload
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="礼物特效"
        :value="form.svga"
        @change="svgaCallBack"
      />
      <a-form-model-item label="装扮类型" prop="installationType" :required="true">
        <a-select placeholder="请选择装扮类型" v-model="form.installationType">
          <a-select-option v-for="(d, index) in installationTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否限时道具" prop="isTimeLimit" :required="true">
        <a-select v-model="form.isTimeLimit" placeholder="请选择是否限时道具" @change="onChangeTimeUnit">
          <a-select-option :value="0">否</a-select-option>
          <a-select-option :value="1">是</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="道具时限" prop="propsTime" v-if="selectTimeUnit == 1">
        <a-input v-model="form.propsTime" placeholder="请输入道具时限" />
      </a-form-model-item>
      <a-form-model-item label="道具时限单位" prop="propsTimeUnit" v-if="selectTimeUnit == 1">
        <a-select placeholder="请选择道具时限单位" v-model="form.propsTimeUnit">
          <a-select-option :value="0">小时</a-select-option>
          <a-select-option :value="1">天</a-select-option>
          <a-select-option :value="2">周</a-select-option>
          <a-select-option :value="3">月</a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="上下架0下架，1上架" prop="status" >
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getProps, addProps, updateProps } from '@/api/biz/props'
import request from "@/utils/request"
import UploadFileToOSS from "@/utils/upload-file-to-oss";
import JImageUpload from '@/components/jeecg/JImageUpload'
import SvgaUpload from '@/components/SvgaUpload'

export default {
  name: 'CreateForm',
  props: {
    accessTypeOptions: {
      type: Array,
      required: true
    },
    installationTypeOptions: {
      type: Array,
      required: true
    },
    isTimeLimitOptions: {
      type: Array,
      required: true
    },
    propsTimeUnitOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    JImageUpload,
    SvgaUpload
  },
  data () {
    return {
      submitLoading: false,
      typeList: [], // 道具类型
      wealthLevelList: [], // 财富等级下拉列表
      uploadLoading: false,
      fileList: [],
      fileList2: [],
      selectTimeUnit: null,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        name: null,

        price: null,

        type: null,

        accessType: null,

        businessId: null,

        logo: null,

        svga: null,

        installationType: null,

        isTimeLimit: null,
        isFullScreen:null,
        propsTime: null,

        propsTimeUnit: null,

        status: 0,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
    this.typeInit();
    this.wealthLevelInit();
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onChangeTimeUnit(value) {
      this.selectTimeUnit = value;
    },
    svgaCallBack(value){
      this.form.svga = value;
    },
    typeInit(){
      request({
        url: '/biz/propsType/list',
        method: 'get'
      }).then(response => {
        this.typeList = response.data;
      })
    },
    wealthLevelInit(){

      request({
        url: '/biz/wealthLevel/list',
        method: 'get',params: { type:1}
      }).then(response => {
        this.wealthLevelList = response.data;
      })
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.logo = info.file.originFileObj.ossUrl;
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        price: null,
        type: null,
        accessType: null,
        businessId: null,
        logo: null,
        svga: null,
        installationType: null,
        isTimeLimit: null,
        propsTime: null,
        propsTimeUnit: null,
        status: 0,
        isFullScreen:null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getProps({"id":id}).then(response => {
        this.form = response.data
        this.form.type = response.data.type +'';
        this.form.accessType = response.data.accessType +'';
        this.form.installationType = response.data.installationType +'';
        this.selectTimeUnit = response.data.isTimeLimit;
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateProps(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addProps(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
