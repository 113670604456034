<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <a-form-item label="道具名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入道具名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="道具获取方式" prop="accessType">
                <a-select placeholder="请选择道具获取方式" v-model="queryParam.accessType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.props_access_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="道具类型" prop="type">
                <a-select placeholder="请选择所属分类" v-model="queryParam.type"  allow-clear>
                  <a-select-option v-for="(item,key) in typeList" :key="item.name" :value="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="装扮类型" prop="installationType">
                <a-select placeholder="请选择装扮类型" v-model="queryParam.installationType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.installation_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="状态" prop="status">
                <a-select placeholder="请选择状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option :value="0">
                      下架
                    </a-select-option>
                    <a-select-option :value="1">
                      正常
                    </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['biz:props:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['biz:props:edit']">
          <a-icon type="edit" />修改
        </a-button>
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['biz:props:remove']">
          <a-icon type="delete" />删除
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :accessTypeOptions="dict.type.props_access_type"
        :installationTypeOptions="dict.type.installation_type"
        :isTimeLimitOptions="dict.type.sys_yes_no"
        :propsTimeUnitOptions="dict.type.time_unit"
        :statusOptions="dict.type.sys_yes_no"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >

        <span slot="logo" slot-scope="text, record">
            <img style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.logo"
                 v-for="(guildLogo,imgIndex) in record.logo.split(',')"
                 :src="guildLogo"
                 preview="图片" />
        </span>

        <span slot="accessType" slot-scope="text, record">
          <dict-tag :options="dict.type['props_access_type']" :value="record.accessType"/>
        </span>
        <span slot="installationType" slot-scope="text, record">
          <dict-tag :options="dict.type['installation_type']" :value="record.installationType"/>
        </span>
        <span slot="isTimeLimit" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no']" :value="record.isTimeLimit"/>
        </span>
        <span slot="propsTimeUnit" slot-scope="text, record">
          <dict-tag :options="dict.type['time_unit']" :value="record.propsTimeUnit"/>
        </span>
        <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_yes_no']" :value="record.status"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['biz:props:edit']" />-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['biz:props:edit']">
            修改
          </a>
          <a-divider type="vertical" v-hasPermi="['biz:props:remove']" />
          <a @click="handleDelete(record.id,1)" v-if="record.status == 0">
            上架
          </a>
          <a @click="handleDelete(record.id,0)" v-if="record.status == 1">
            下架
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageProps, listProps, delProps, updateStatus} from '@/api/biz/props'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import request from '@/utils/request'

export default {
  name: 'Props',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  dicts: ['props_access_type', 'installation_type', 'time_unit', 'sys_yes_no'],
  data () {
    return {
      list: [],
      typeList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        price: null,
        type: null,
        accessType: null,
        businessId: null,
        logo: null,
        svga: null,
        installationType: null,
        isTimeLimit: null,
        propsTime: null,
        propsTimeUnit: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: '',
          key: 'rowIndex',
          width: 60,
          align: 'center',
          customRender: function (t, r, index) {
            return parseInt(index) + 1
          }
        },
        {
          title: '道具LOGO',
          dataIndex: 'logo',width:90,
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "logo"}
        },
        {
          title: '道具名称',
          dataIndex: 'name',
          ellipsis: true,width: 120,
          align: 'center'
        },
        {
          title: '价格',
          dataIndex: 'price',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '道具类型',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '道具获取方式', width: 100,
          dataIndex: 'accessType',
          scopedSlots: { customRender: 'accessType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '到达财富等级', width: 100,
          dataIndex: 'wealthLevelName',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == null){
              return "-"
            }else {
              return value
            }
          }
        },
        {
          title: '充值到达金额', width: 100,
          dataIndex: 'rechargeMoney',
          ellipsis: true,
          align: 'center', customRender: function (value) {
            if (value == null){
              return "-"
            } else {
              return value
            }
          }
        },
        {
          title: '装扮类型',
          dataIndex: 'installationType',
          scopedSlots: { customRender: 'installationType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否限时',
          dataIndex: 'isTimeLimit',
          scopedSlots: { customRender: 'isTimeLimit' },
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 0){
              return "否"
            }
            if (value == 1){
              return "是"
            }
          }
        },
        {
          title: '道具时限',
          dataIndex: 'propsTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '时限单位',
          dataIndex: 'propsTimeUnit',
          scopedSlots: { customRender: 'propsTimeUnit' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '全屏显示',
          dataIndex: 'isTimeLimit',
          scopedSlots: { customRender: 'isTimeLimit' },
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return "是"
            }else{
              return "否"
            }
          }
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 0){
              return "下架"
            }
            if (value == 1){
              return "正常"
            }
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,width: 160,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.typeInit()
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {

    typeInit(){
      request({
        url: '/biz/propsType/list',
        method: 'get'
      }).then(response => {
        this.typeList = response.data;
      })
    },
    /** 查询道具信息列表 */
    getList () {
      this.loading = true
     pageProps(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        price: undefined,
        type: undefined,
        accessType: undefined,
        businessId: undefined,
        logo: undefined,
        svga: undefined,
        installationType: undefined,
        isTimeLimit: undefined,
        propsTime: undefined,
        propsTimeUnit: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (id,status) {
      let that = this;
      that.$confirm({
        title: "确定"+(status == 1 ? "上架" : "下架")+"此商品嘛?",
        content: '',
        onOk () {
          let data = {
            "id":id,
            "status":status
          }
          return updateStatus(data)
            .then(() => {
              that.getList()
              that.$message.success(
                '修改成功',
                3
              )
          })
        },
        onCancel () {

        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('biz/propsInfo/export', {
            ...that.queryParam
          }, `道具信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
