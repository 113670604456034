import request from '@/utils/request'


// 查询道具信息列表
export function listProps(query) {
  return request({
    url: '/biz/propsInfo/list',
    method: 'get',
    params: query
  })
}

// 查询道具信息分页
export function pageProps(query) {
  return request({
    url: '/biz/propsInfo/page',
    method: 'get',
    params: query
  })
}

// 查询道具信息详细
export function getProps(data) {
  return request({
    url: '/biz/propsInfo/detail',
    method: 'get',
    params: data
  })
}

// 新增道具信息
export function addProps(data) {
  return request({
    url: '/biz/propsInfo/add',
    method: 'post',
    data: data
  })
}

// 修改道具信息
export function updateProps(data) {
  return request({
    url: '/biz/propsInfo/edit',
    method: 'post',
    data: data
  })
}

// 删除道具信息
export function delProps(data) {
  return request({
    url: '/biz/propsInfo/delete',
    method: 'post',
    data: data
  })
}

// 上下架道具信息
export function updateStatus(data) {
  return request({
    url: '/biz/propsInfo/updateStatus',
    method: 'post',
    data: data
  })
}